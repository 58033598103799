import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/curriculum/:component*',
    name: 'curriculum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CurriculumView.vue'),
    props: true
  },
  {
    path: '/notice/:post*',
    name: 'notice',
    // route level code-splitting
    // this generates a separate chunk (board.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/NoticeView.vue'),
    props: true
  },
  {
    path: '/downloads/:post*',
    name: 'downloads',
    // route level code-splitting
    // this generates a separate chunk (board.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/DownloadsView.vue'),
    props: true
  },
  {
    path: '/qna/:post*',
    name: 'qna',
    // route level code-splitting
    // this generates a separate chunk (board.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/QnaView.vue'),
    props: true
  },
  // {
  //   path: '/consultation/admission/:post*',
  //   name: 'admission',
  //   // route level code-splitting
  //   // this generates a separate chunk (board.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "board" */ '../views/AdmissionConsultationView.vue'),
  //   props: true
  // },
  // {
  //   path: '/consultation/learning-design/:post*',
  //   name: 'learning',
  //   // route level code-splitting
  //   // this generates a separate chunk (board.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "board" */ '../views/LearningConsultationView.vue'),
  //   props: true
  // },
  // {
  //   path: '/consultation/phone/:post*',
  //   name: 'phone',
  //   // route level code-splitting
  //   // this generates a separate chunk (board.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "board" */ '../views/PhoneConsultationView.vue'),
  //   props: true
  // },
  {
    path: '/application/:component*',
    name: 'application',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ApplicationView.vue'),
    props: true
  },
  {
    path: '/page7',
    name: 'page7',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page7View.vue')
  },
  {
    path: '/page8',
    name: 'page8',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page8View.vue')
  },
  {
    path: '/page9',
    name: 'page9',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page9View.vue')
  },
  {
    path: '/page10',
    name: 'page10',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page10View.vue')
  },
  {
    path: '/page11',
    name: 'page11',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page11View.vue')
  },
  {
    path: '/page12',
    name: 'page12',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page12View.vue')
  },
  {
    path: '/page13',
    name: 'page13',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page13View.vue')
  },
  {
    path: '/page14',
    name: 'page14',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page14View.vue')
  },
  {
    path: '/page15',
    name: 'page15',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Page15View.vue')
  },
  {
    path: '/page16/:post*',
    name: 'page16',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/Page16View.vue'),
    props: true
  },
  {
    path: '/page17/:post*',
    name: 'page17',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/Page17View.vue'),
    props: true
  },
  {
    path: '/page18/:post*',
    name: 'page18',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "board" */ '../views/Page18View.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
