import { createStore } from 'vuex'

export default createStore({
  state: {
    url: 'https://ysarts-yonsei.ac.kr/',
    // url: 'http://vue.test:8080/',
    // url: 'http://api.test/',
    isLoading: true,
    isConnecting: false,
    appToken: '',
    menu: [
      { menuId: 1, parent: 0, title: '예술원소개', url: '', path: '/about', target: 'child', active: false },
      { menuId: 2, parent: 0, title: '교과과정', url: '', path: '/curriculum', target: 'child', active: false },
      { menuId: 3, parent: 0, title: '원서접수', url: '', path: '/application', target: 'self', active: false },
      // { menuId: 4, parent: 0, title: '상담신청', url: '', path: '/consultation/admission', target: 'child', active: false },
      { menuId: 6, parent: 0, title: '학위취득', url: '', path: '/page15', target: 'self', active: false },
      { menuId: 24, parent: 0, title: '장학제도', url: '', path: '/page14', target: 'self', active: false },
      { menuId: 5, parent: 0, title: '커뮤니티', url: '', path: '/notice', target: 'child', active: false },
      { menuId: 7, parent: 1, title: '기관 소개', url: '', path: '/about', target: 'self', active: false },
      { menuId: 8, parent: 1, title: '원장 인사말', url: '', path: '/page7', target: 'self', active: false },
      { menuId: 9, parent: 1, title: '비전', url: '', path: '/page8', target: 'self', active: false },
      { menuId: 10, parent: 1, title: '교수진 소개', url: '', path: '/page9', target: 'self', active: false },
      { menuId: 11, parent: 1, title: '시설 안내', url: '', path: '/page10', target: 'self', active: false },
      { menuId: 12, parent: 1, title: '찾아오시는 길', url: '', path: '/page11', target: 'self', active: false },
      { menuId: 12, parent: 1, title: '조직 및 업무', url: '', path: '/page12', target: 'self', active: false },
      { menuId: 13, parent: 2, title: '영화학', url: '', path: '/curriculum/film', target: 'self', active: false },
      // { menuId: 14, parent: 2, title: '연극학', url: '', path: '/curriculum/theatre', target: 'self', active: false },
      { menuId: 15, parent: 2, title: '실용음악학', url: '', path: '/curriculum/music', target: 'self', active: false },
      // { menuId: 16, parent: 3, title: '모집요강', url: '', path: '/application', target: 'self', active: false },
      // { menuId: 17, parent: 4, title: '입학상담', url: '', path: '/consultation/admission', target: 'self', active: false },
      // { menuId: 18, parent: 4, title: '학습설계상담', url: '', path: '/consultation/learning-design', target: 'self', active: false },
      // { menuId: 19, parent: 4, title: '전화상담신청', url: '', path: '/consultation/phone', target: 'self', active: false },
      { menuId: 20, parent: 5, title: '공지사항', url: '', path: '/notice', target: 'self', active: false },
      { menuId: 25, parent: 5, title: '학교소식', url: '', path: '/page16', target: 'self', active: false },
      { menuId: 27, parent: 5, title: '활동소개', url: '', path: '/page18', target: 'self', active: false },
      { menuId: 21, parent: 5, title: '자료실', url: '', path: '/downloads', target: 'self', active: false },
      { menuId: 22, parent: 5, title: 'QnA', url: '', path: '/qna', target: 'self', active: false },
      { menuId: 23, parent: 5, title: '안내책자신청', url: '', path: '/page13', target: 'self', active: false },
      { menuId: 26, parent: 5, title: '진학상담신청', url: '', path: '/page17', target: 'self', active: false }
    ],
    snsLinks: [
      { snsId: 1, title: 'instagram', url: 'https://www.instagram.com/ys_arts_official/' },
      // { snsId: 2, title: 'facebook', url: 'https://www.facebook.com/ysartsinstitute' },
      // { snsId: 3, title: 'twitter', url: 'https://twitter.com/ysarts_official' },
      { snsId: 4, title: 'blog', url: 'https://blog.naver.com/ysarts-yonsei' },
      { snsId: 5, title: 'youtube', url: 'https://www.youtube.com/@user-lr4eb1zx9b' }
    ],
    application: {
      component: 'about',
      data: {
        agreement: {
          agreement1: null,
          agreement2: null,
          agreement3: null,
          agreement4: null,
          agreement5: null
        },
        authentication: {
          name: null,
          phone: null,
          nonce: null
        },
        form: {
          formData: {
            field5: ''
          }
        }
      }
    },
    modalData: {
      modalType: 'alert',
      bodyText: '',
      confirmText: '',
      cancelText: ''
    },
    countryCode: [
      { id: 'GH', name: '가나' },
      { id: 'GA', name: '가봉' },
      { id: 'GY', name: '가이아나' },
      { id: 'GM', name: '감비아' },
      { id: 'GG', name: '건지 섬' },
      { id: 'GP', name: '과들루프' },
      { id: 'GT', name: '과테말라' },
      { id: 'GU', name: '괌' },
      { id: 'GD', name: '그레나다' },
      { id: 'GR', name: '그리스' },
      { id: 'GL', name: '그린란드' },
      { id: 'GN', name: '기니' },
      { id: 'GW', name: '기니비사우' },
      { id: 'NA', name: '나미비아' },
      { id: 'NR', name: '나우루' },
      { id: 'NG', name: '나이지리아' },
      { id: 'AQ', name: '남극' },
      { id: 'SS', name: '남수단' },
      { id: 'ZA', name: '남아프리카 공화국' },
      { id: 'NL', name: '네덜란드' },
      { id: 'AN', name: '네덜란드령 안틸레스' },
      { id: 'NP', name: '네팔' },
      { id: 'NO', name: '노르웨이' },
      { id: 'NF', name: '노퍽 섬' },
      { id: 'NC', name: '누벨칼레도니' },
      { id: 'NZ', name: '뉴질랜드' },
      { id: 'NU', name: '니우에' },
      { id: 'NE', name: '니제르' },
      { id: 'NI', name: '니카라과' },
      { id: 'KR', name: '대한민국' },
      { id: 'DK', name: '덴마크' },
      { id: 'DO', name: '도미니카 공화국' },
      { id: 'DM', name: '도미니카 연방' },
      { id: 'DE', name: '독일' },
      { id: 'TL', name: '동티모르' },
      { id: 'LA', name: '라오스' },
      { id: 'LR', name: '라이베리아' },
      { id: 'LV', name: '라트비아' },
      { id: 'RU', name: '러시아' },
      { id: 'LB', name: '레바논' },
      { id: 'LS', name: '레소토' },
      { id: 'RE', name: '레위니옹' },
      { id: 'RO', name: '루마니아' },
      { id: 'LU', name: '룩셈부르크' },
      { id: 'RW', name: '르완다' },
      { id: 'LY', name: '리비아' },
      { id: 'LT', name: '리투아니아' },
      { id: 'LI', name: '리히텐슈타인' },
      { id: 'MG', name: '마다가스카르' },
      { id: 'MQ', name: '마르티니크' },
      { id: 'MH', name: '마셜 제도' },
      { id: 'YT', name: '마요트' },
      { id: 'MO', name: '마카오' },
      { id: 'MK', name: '마케도니아 공화국' },
      { id: 'MW', name: '말라위' },
      { id: 'MY', name: '말레이시아' },
      { id: 'ML', name: '말리' },
      { id: 'IM', name: '맨 섬' },
      { id: 'MX', name: '멕시코' },
      { id: 'MC', name: '모나코' },
      { id: 'MA', name: '모로코' },
      { id: 'MU', name: '모리셔스' },
      { id: 'MR', name: '모리타니' },
      { id: 'MZ', name: '모잠비크' },
      { id: 'ME', name: '몬테네그로' },
      { id: 'MS', name: '몬트세랫' },
      { id: 'MD', name: '몰도바' },
      { id: 'MV', name: '몰디브' },
      { id: 'MT', name: '몰타' },
      { id: 'MN', name: '몽골' },
      { id: 'US', name: '미국' },
      { id: 'UM', name: '미국령 군소 제도' },
      { id: 'VI', name: '미국령 버진아일랜드' },
      { id: 'MM', name: '미얀마' },
      { id: 'FM', name: '미크로네시아 연방' },
      { id: 'VU', name: '바누아투' },
      { id: 'BH', name: '바레인' },
      { id: 'BB', name: '바베이도스' },
      { id: 'VA', name: '바티칸 시국' },
      { id: 'BS', name: '바하마' },
      { id: 'BD', name: '방글라데시' },
      { id: 'BM', name: '버뮤다' },
      { id: 'BJ', name: '베냉' },
      { id: 'VE', name: '베네수엘라' },
      { id: 'VN', name: '베트남' },
      { id: 'BE', name: '벨기에' },
      { id: 'BY', name: '벨라루스' },
      { id: 'BZ', name: '벨리즈' },
      { id: 'BA', name: '보스니아 헤르체고비나' },
      { id: 'BW', name: '보츠와나' },
      { id: 'BO', name: '볼리비아' },
      { id: 'BI', name: '부룬디' },
      { id: 'BF', name: '부르키나파소' },
      { id: 'BV', name: '부베 섬' },
      { id: 'BT', name: '부탄' },
      { id: 'MP', name: '북마리아나 제도' },
      { id: 'BG', name: '불가리아' },
      { id: 'BR', name: '브라질' },
      { id: 'BN', name: '브루나이' },
      { id: 'WS', name: '사모아' },
      { id: 'SA', name: '사우디아라비아' },
      { id: 'GS', name: '사우스조지아 사우스샌드위치 제도' },
      { id: 'SM', name: '산마리노' },
      { id: 'ST', name: '상투메 프린시페' },
      { id: 'PM', name: '생피에르 미클롱' },
      { id: 'EH', name: '서사하라' },
      { id: 'SN', name: '세네갈' },
      { id: 'RS', name: '세르비아' },
      { id: 'SC', name: '세이셸' },
      { id: 'LC', name: '세인트루시아' },
      { id: 'VC', name: '세인트빈센트 그레나딘' },
      { id: 'KN', name: '세인트키츠 네비스' },
      { id: 'SH', name: '세인트헬레나' },
      { id: 'SO', name: '소말리아' },
      { id: 'SB', name: '솔로몬 제도' },
      { id: 'SD', name: '수단' },
      { id: 'SR', name: '수리남' },
      { id: 'LK', name: '스리랑카' },
      { id: 'SJ', name: '스발바르 얀마옌' },
      { id: 'SZ', name: '스와질란드' },
      { id: 'SE', name: '스웨덴' },
      { id: 'CH', name: '스위스' },
      { id: 'ES', name: '스페인' },
      { id: 'SK', name: '슬로바키아' },
      { id: 'SI', name: '슬로베니아' },
      { id: 'SY', name: '시리아' },
      { id: 'SL', name: '시에라리온' },
      { id: 'SG', name: '싱가포르' },
      { id: 'AE', name: '아랍에미리트' },
      { id: 'AW', name: '아루바' },
      { id: 'AM', name: '아르메니아' },
      { id: 'AR', name: '아르헨티나' },
      { id: 'AS', name: '아메리칸사모아' },
      { id: 'IS', name: '아이슬란드' },
      { id: 'HT', name: '아이티' },
      { id: 'IE', name: '아일랜드' },
      { id: 'AZ', name: '아제르바이잔' },
      { id: 'AF', name: '아프가니스탄' },
      { id: 'AD', name: '안도라' },
      { id: 'AL', name: '알바니아' },
      { id: 'DZ', name: '알제리' },
      { id: 'AO', name: '앙골라' },
      { id: 'AG', name: '앤티가 바부다' },
      { id: 'AI', name: '앵귈라' },
      { id: 'ER', name: '에리트레아' },
      { id: 'EE', name: '에스토니아' },
      { id: 'EC', name: '에콰도르' },
      { id: 'ET', name: '에티오피아' },
      { id: 'SV', name: '엘살바도르' },
      { id: 'GB', name: '영국' },
      { id: 'VG', name: '영국령 버진아일랜드' },
      { id: 'IO', name: '영국령 인도양 지역' },
      { id: 'YE', name: '예멘' },
      { id: 'OM', name: '오만' },
      { id: 'AU', name: '오스트레일리아' },
      { id: 'AT', name: '오스트리아' },
      { id: 'HN', name: '온두라스' },
      { id: 'AX', name: '올란드 제도' },
      { id: 'WF', name: '왈리스 퓌튀나' },
      { id: 'JO', name: '요르단' },
      { id: 'UG', name: '우간다' },
      { id: 'UY', name: '우루과이' },
      { id: 'UZ', name: '우즈베키스탄' },
      { id: 'UA', name: '우크라이나' },
      { id: 'IQ', name: '이라크' },
      { id: 'IR', name: '이란' },
      { id: 'IL', name: '이스라엘' },
      { id: 'EG', name: '이집트' },
      { id: 'IT', name: '이탈리아' },
      { id: 'IN', name: '인도' },
      { id: 'ID', name: '인도네시아' },
      { id: 'JP', name: '일본' },
      { id: 'JM', name: '자메이카' },
      { id: 'ZM', name: '잠비아' },
      { id: 'JE', name: '저지 섬' },
      { id: 'GQ', name: '적도 기니' },
      { id: 'KP', name: '조선민주주의인민공화국' },
      { id: 'GE', name: '조지아' },
      { id: 'CF', name: '중앙아프리카 공화국' },
      { id: 'TW', name: '중화민국' },
      { id: 'CN', name: '중화인민공화국' },
      { id: 'DJ', name: '지부티' },
      { id: 'GI', name: '지브롤터' },
      { id: 'ZW', name: '짐바브웨' },
      { id: 'TD', name: '차드' },
      { id: 'CZ', name: '체코' },
      { id: 'CL', name: '칠레' },
      { id: 'CM', name: '카메룬' },
      { id: 'CV', name: '카보베르데' },
      { id: 'KZ', name: '카자흐스탄' },
      { id: 'QA', name: '카타르' },
      { id: 'KH', name: '캄보디아' },
      { id: 'CA', name: '캐나다' },
      { id: 'KE', name: '케냐' },
      { id: 'KY', name: '케이맨 제도' },
      { id: 'KM', name: '코모로' },
      { id: 'CR', name: '코스타리카' },
      { id: 'CC', name: '코코스 제도' },
      { id: 'CI', name: '코트디부아르' },
      { id: 'CO', name: '콜롬비아' },
      { id: 'CG', name: '콩고 공화국' },
      { id: 'CD', name: '콩고 민주 공화국' },
      { id: 'CU', name: '쿠바' },
      { id: 'KW', name: '쿠웨이트' },
      { id: 'CK', name: '쿡 제도' },
      { id: 'HR', name: '크로아티아' },
      { id: 'CX', name: '크리스마스 섬' },
      { id: 'KG', name: '키르기스스탄' },
      { id: 'KI', name: '키리바시' },
      { id: 'CY', name: '키프로스' },
      { id: 'TH', name: '타이' },
      { id: 'TJ', name: '타지키스탄' },
      { id: 'TZ', name: '탄자니아' },
      { id: 'TC', name: '터크스 케이커스 제도' },
      { id: 'TR', name: '터키' },
      { id: 'TG', name: '토고' },
      { id: 'TK', name: '토켈라우' },
      { id: 'TO', name: '통가' },
      { id: 'TM', name: '투르크메니스탄' },
      { id: 'TV', name: '투발루' },
      { id: 'TN', name: '튀니지' },
      { id: 'TT', name: '트리니다드 토바고' },
      { id: 'PA', name: '파나마' },
      { id: 'PY', name: '파라과이' },
      { id: 'PK', name: '파키스탄' },
      { id: 'PG', name: '파푸아 뉴기니' },
      { id: 'PW', name: '팔라우' },
      { id: 'PS', name: '팔레스타인' },
      { id: 'FO', name: '페로 제도' },
      { id: 'PE', name: '페루' },
      { id: 'PT', name: '포르투갈' },
      { id: 'FK', name: '포클랜드 제도' },
      { id: 'PL', name: '폴란드' },
      { id: 'PR', name: '푸에르토리코' },
      { id: 'FR', name: '프랑스' },
      { id: 'GF', name: '프랑스령 기아나' },
      { id: 'TF', name: '프랑스령 남부와 남극 지역' },
      { id: 'PF', name: '프랑스령 폴리네시아' },
      { id: 'FJ', name: '피지' },
      { id: 'FI', name: '핀란드' },
      { id: 'PH', name: '필리핀' },
      { id: 'PN', name: '핏케언 제도' },
      { id: 'HM', name: '허드 맥도널드 제도' },
      { id: 'HU', name: '헝가리' },
      { id: 'HK', name: '홍콩' }
    ],
    boards: {
      notice: {
        boardId: 1,
        route: 'notice',
        name: '공지사항',
        defaultText: '',
        defaultSecret: false,
        postId: 0,
        page: 1,
        action: '',
        keyword: '',
        categories: null,
        category: ''
      },
      downloads: {
        boardId: 2,
        route: 'downloads',
        name: '자료실',
        defaultText: '',
        defaultSecret: false,
        postId: 0,
        page: 1,
        action: '',
        keyword: '',
        categories: null,
        category: ''
      },
      qna: {
        boardId: 3,
        route: 'qna',
        name: 'QnA',
        defaultText: '<p>*휴대전화와 이메일주소는 필수로 입력해주세요.</p><p>휴대전화번호:</p><p>이메일:</p><p>문의내용:</p><p>&nbsp;</p>',
        defaultSecret: true,
        postId: 0,
        page: 1,
        action: '',
        keyword: '',
        categories: null,
        category: ''
      },
      news: {
        boardId: 7,
        route: 'page16',
        name: '학교소식',
        defaultText: '',
        defaultSecret: false,
        postId: 0,
        page: 1,
        action: '',
        keyword: '',
        categories: null,
        category: ''
      },
      p18: {
        boardId: 8,
        route: 'page18',
        name: '활동안내',
        defaultText: '',
        defaultSecret: false,
        postId: 0,
        page: 1,
        action: '',
        keyword: '',
        categories: null,
        category: ''
      // },
      // admission: {
      //   boardId: 4,
      //   route: 'admission',
      //   name: '입학상담',
      //   defaultText: '',
      //   defaultSecret: true,
      //   postId: 0,
      //   page: 1,
      //   action: '',
      //   keyword: ''
      // },
      // learning: {
      //   boardId: 5,
      //   route: 'learning',
      //   name: '학습설계상담',
      //   defaultText: '',
      //   defaultSecret: true,
      //   postId: 0,
      //   page: 1,
      //   action: '',
      //   keyword: ''
      // },
      // phone: {
      //   boardId: 6,
      //   route: 'phone',
      //   name: '전화상담신청',
      //   defaultText: '<p>이름:</p><p>연락처:</p><p>원하시는 상담시간:</p><p>문의내용:</p>',
      //   defaultSecret: true,
      //   postId: 0,
      //   page: 1,
      //   action: '',
      //   keyword: ''
      }
    },
    popup: [],
    alert: {
      isShown: false,
      message: '',
      confirmText: '제출',
      closeText: '확인',
      confirmView: false,
      callback: null,
      callbackParams: null
    }
  },
  getters: {
    getLoadingState: (state) => {
      return state.isLoading
    },
    getApplication: (state) => {
      return state.application
    },
    getApplicationComponent: (state) => {
      return state.application.component
    },
    getToken(state) {
      return state.appToken
    }
  },
  mutations: {
    updateLoadingState: (state, payload) => {
      state.isLoading = payload
    },
    updateApplicationComponent: (state, payload) => {
      state.application.component = payload
    },
    clearApplicationData: (state) => {
      state.application.data = {
        agreement: {
          agreement1: null,
          agreement2: null,
          agreement3: null,
          agreement4: null,
          agreement5: null
        },
        authentication: {
          name: null,
          phone: null
        },
        form: {
          formData: {
            field5: ''
          }
        }
      }
    },
    clearBoardData: (state, payload) => {
      const board = state.boards[payload]

      board.postId = 0
      board.page = 1
      board.action = ''
      board.keyword = ''
      board.category = ''
    },
    updateToken: (state, payload) => {
      state.appToken = payload
      document.cookie = 'APPTOKEN=' + payload + ';path=/'
    },
    updateAlertData: (state, payload) => {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(state.alert, key) && Object.hasOwnProperty.call(payload, key)) {
          state.alert[key] = payload[key]
        }
      }
    },
    clearAlertData: (state) => {
      state.alert = {
        isShown: false,
        message: '',
        confirmText: '제출',
        closeText: '확인',
        confirmView: false,
        callback: null,
        callbackParams: null
      }
    },
    updataActiveMenu: (state, payload) => {
      for (const key in state.menu) {
        if (Object.hasOwnProperty.call(state.menu, key)) {
          const element = state.menu[key]

          if (element.menuId === payload.menuId) {
            element.active = true
          } else if (payload.parent > 0 && element.menuId === payload.parent) {
            element.active = true
          } else {
            element.active = false
          }
        }
      }
    }
  },
  actions: {
    openAlert: (context, payload) => {
      payload.isShown = true
      context.commit('updateAlertData', payload)
    },
    routeChange: (context, payload) => {
      const activeMenu = context.state.menu.filter(menu => menu.path === payload)[0]
      if (activeMenu) {
        context.commit('updataActiveMenu', activeMenu)
      }
    }
  },
  modules: {
  }
})
