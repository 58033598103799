<template>
  <div class="page home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="s1">
            <h1 ref="hidden1" class="hidden slide fade">연세대학교가<br>대중예술교육을 시작합니다.</h1>
            <h2 ref="hidden2" class="hidden slide fade">Yonsei Institute of the Arts</h2>
            <div class="c1">
              <h4>연세예술원</h4>
              전공, 매체와 장르, 국가의 경계를 넘어서는 다중융합교육을 통해 예술 현장과 직접 연결된 작품을 제작하여 부가가치를 창조하는 산업 연계교육을 선도하는 기관입니다.
            </div>
            <div class="c2">
              <h4>교과과정</h4>
              <!-- <div class="d-lg-none">
                <router-link :to="{ name: 'curriculum', params: { component: 'film' } }" class="btn btn-outline-dark">영화학</router-link>
                <router-link :to="{ name: 'curriculum', params: { component: 'theatre' } }" class="btn btn-outline-dark">연극학</router-link>
                <router-link :to="{ name: 'curriculum', params: { component: 'music' } }" class="btn btn-outline-dark">실용음악학</router-link>
              </div> -->
              <div class="p d-none d-lg-block">
                <router-link :to="{ name: 'curriculum', params: { component: 'film' } }" class="btn btn-p btn-outline-dark">
                  <h6>영화학</h6>
                  <p>영화 창작</p>
                  <p>드라마 창작</p>
                  <p>매체 연기</p>
                </router-link>
                <router-link :to="{ name: 'curriculum', params: { component: 'music' } }" class="btn btn-p btn-outline-dark">
                  <h6>실용음악학</h6>
                  <p>싱어송라이터</p>
                  <p>보컬</p>
                  <p>작사</p>
                </router-link>
              </div>
              <div class="m d-lg-none">
                <router-link :to="{ name: 'curriculum', params: { component: 'film' } }" class="btn btn-outline-dark">
                  <h5>영화학<span>&nbsp;&nbsp;&nbsp;영화 창작&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;드라마 창작&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;매체 연기</span></h5>
                </router-link>
                <router-link :to="{ name: 'curriculum', params: { component: 'music' } }" class="btn btn-outline-dark">
                  <h5>실용음악학<span>&nbsp;&nbsp;&nbsp;싱어송라이터&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;보컬&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;작사</span></h5>
                </router-link>
              </div>
            </div>
            <div class="c3" @click="scrollTo('.s8')">
              <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="4 4 16 16" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
            </div>
          </div>
          <div class="s7">
            <div>
              <iframe src="https://www.youtube.com/embed/oaRXWaXrD5s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div class="s2">
            <h1 ref="hidden3" class="hidden slide fade">시설안내</h1>
            <div id="carouselHome" class="carousel slide" data-bs-ride="true">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../assets/images/carousel/1.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/2.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/3.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/4.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/5.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/6.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/7.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/8.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/9.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/10.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/11.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/12.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/13.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/14.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/15.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/16.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/17.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/18.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/19.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/20.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/21.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/22.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/23.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/24.jpg" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/carousel/25.jpg" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="s8">
            <h1 ref="hidden4" class="hidden slide fade">교과과정</h1>
            <div class="v-menu">
              <div class="v-item" :class="{active: s8Active === 'film'}" @mouseenter="vmenuHover('film')" @click="vmenuClick('film')">
                <div class="v-item-bg">
                  <img src="../assets/images/a87eec14973ab.jpg" alt="">
                </div>
                <p class="v-item-head">영화학</p>
                <p class="v-item-text fw-bold mb-2">
                  [영화 창작 / 드라마 창작 / 매체 연기]
                </p>
                <br>
                <p class="v-item-text">
                  영화학 전공은 영화를 직접 제작하는 현장 실무형 교육을 진행하고<br class="d-none d-lg-inline">
                  현장에서 필요로 하는 핵심 인력 양성이 목적인 전공입니다.
                </p>
                <div class="v-item-button" @click.stop="vmenuExtend('film')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="4 4 14 16" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
              </div>
              <div class="v-item" :class="{active: s8Active === 'music'}" @mouseenter="vmenuHover('music')" @click="vmenuClick('music')">
                <div class="v-item-bg">
                  <img class="v-item-bg" src="../assets/images/47565f36bc612.jpg" alt="">
                </div>
                <p class="v-item-head">실용음악학</p>
                <p class="v-item-text fw-bold mb-2">
                  [싱어송라이터 / 보컬 / 작사]
                </p>
                <br>
                <p class="v-item-text">
                  실용음악학 전공은 실용적이고 현장 중심적 내용을 기반한 음악 교육을 진행해<br class="d-none d-lg-inline">시대가 요구하는 창의적 뮤지션 양성이 목적인 전공입니다.
                </p>
                <div class="v-item-button" @click.stop="vmenuExtend('music')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="4 4 14 16" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="s3">
            <div class="c1">
              <p class="v-text d-none d-lg-block">교과과정</p>
              <p class="text d-lg-none">교과과정</p>
              <p class="subheading">영화학 전공</p>
              <router-link :to="{name: 'curriculum', params: {component: 'film'}}" class="link">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-lg" viewBox="2 2 12 12">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg>
              </router-link>
            </div>
            <div class="c2">
              <p>영화학 전공은 영화를 직접 제작하는 현장 실무형 교육을 진행하고 현장에서 필요로 하는 핵심 인력 양성이 목적인 전공입니다.</p>
            </div>
          </div>
          <div class="s4">
            <div class="c1">
              <p class="v-text d-none d-lg-block">교과과정</p>
              <p class="text d-lg-none">교과과정</p>
              <p class="subheading">연극학(뮤지컬) 전공</p>
              <router-link :to="{name: 'curriculum', params: {component: 'theatre'}}" class="link">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-lg" viewBox="2 2 12 12">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg>
              </router-link>
            </div>
            <div class="c2">
              <p>연극학 전공은 연극/뮤지컬 배우 및 창작스태프 양성이 목적인 전공으로, 현장 경험이 풍부한 교수진이 실제 공연제작을 중심으로 실기 및 이론 과목을 교육합니다.</p>
            </div>
          </div>
          <div class="s5">
            <div class="c1">
              <p class="v-text d-none d-lg-block">교과과정</p>
              <p class="text d-lg-none">교과과정</p>
              <p class="subheading">실용음악학 전공</p>
              <router-link :to="{name: 'curriculum', params: {component: 'music'}}" class="link">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-lg" viewBox="2 2 12 12">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg>
              </router-link>
            </div>
            <div class="c2">
              <p>실용음악학 전공은 실용적이고 현장 중심적 내용을 기반한 음악 교육을 진행해 시대가 요구하는 창의적 뮤지션 양성이 목적인 전공입니다.</p>
            </div>
          </div> -->
          <div class="s6">
            <div class="row">
              <div class="col-lg-7 offset-lg-5">
                <p ref="hidden5" class="subheading hidden slide fade">공지사항</p>
                <table class="table table-hover">
                  <tbody>
                    <tr :key="item.id" v-for="item in posts">
                      <td class="w-100">
                        <router-link :to="{name: 'notice', params: {post: item.id}}" class="link">{{item.title}}</router-link>
                      </td>
                      <td class="text-nowrap">{{formatDate(item.created_at)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="popup_we8vf9es" class="popup" v-if="popup_we8vf9es">
      <router-link :to="{name: 'application'}" class="link">
        <img src="../assets/images/087014888352e.png" alt="...">
      </router-link>
      <div class="close-popup" @click="closePopup('popup_we8vf9es')"></div>
      <div class="close-oneday" @click="closePopup('popup_we8vf9es', true)">오늘 하루 이창 열지 않기</div>
    </div>
    <!-- <div id="popup_8hqf239r" class="popup" v-if="popup_8hqf239r">
      <img src="../assets/images/af939ed110b4d.png" alt="...">
      <div class="row g-0 border-top">
        <div class="col text-center p-3 border-end close-oneDay" @click="closePopup('popup_8hqf239r', true)">1일동안 보지 않음</div>
        <div class="col text-center p-3 close-popup" @click="closePopup('popup_8hqf239r')">닫기</div>
      </div>
    </div>
    <div id="popup_ab48ee5b" class="popup" v-if="popup_ab48ee5b">
      <img src="../assets/images/90ac8c1945d0f.jpg" alt="...">
      <div class="row g-0 border-top">
        <div class="col text-center p-3 border-end close-oneDay" @click="closePopup('popup_ab48ee5b', true)">1일동안 보지 않음</div>
        <div class="col text-center p-3 close-popup" @click="closePopup('popup_ab48ee5b')">닫기</div>
      </div>
    </div> -->
    <PopupComponent v-for="(item, index) in $store.state.popup" :key="index"
      :name="item.name"
      :url="item.url"
      :link="item.link"
      :visible="item.visible"
      :top="item.top"
      :position="item.position"
      :pixel="item.pixel"
      :width="item.width"
      :visible_m="item.visible_m"
      :top_m="item.top_m"
      :position_m="item.position_m"
      :pixel_m="item.pixel_m"
      :width_m="item.width_m"
    />
  </div>
</template>

<script>
import PopupComponent from '@/components/PopupComponent.vue'
export default {
  name: 'HomeView',
  components: {
    PopupComponent
  },
  data() {
    return {
      menu: this.$store.state.menu,
      sns: this.$store.state.snsLinks,
      posts: [],
      s8Active: 'film',
      popup_we8vf9es: true,
      popup_8hqf239r: true,
      popup_ab48ee5b: true
    }
  },
  created() {
    scrollTo({ top: 0, left: 0, behavior: 'instant' })
    this.$store.dispatch('routeChange', this.$route.path)

    document.cookie.split(';').forEach((el) => {
      const [key, value] = el.split('=')
      const timestamp = Date.now()
      if (this[key.trim()] !== undefined && value > timestamp - 24 * 60 * 60 * 1000) {
        this[key.trim()] = false
      }
    })
  },
  mounted() {
    this.$axios.get(`${this.$store.state.url}api/v1/board/1/post?is_notice=1&posts_per_page=5`, {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
      this.posts = res.data.items
    }).catch(() => {
    })

    if (this.$store.state.popup.length === 0) {
      this.getPopup()
    }

    this.$store.commit('updateLoadingState', false)
    this.$emit('mounted')
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat().format(date)
    },
    scrollTo(target) {
      const elem = document.querySelector(target)
      const rect = elem.getBoundingClientRect()
      scrollTo({ top: rect.top, left: 0, behavior: 'smooth' })
    },
    closePopup(target, oneday = false) {
      if (this[target] !== undefined) {
        this[target] = false

        if (oneday === true) {
          document.cookie = target + '=' + Date.now()
        }
      }
    },
    getCookie(name) {
      const cookie = {}
      document.cookie.split(';').forEach(function(el) {
        const [key, value] = el.split('=')
        cookie[key.trim()] = value
      })
      return cookie[name]
    },
    vmenuHover(page) {
      // event.target.parentNode.childNodes.forEach(elem => elem.classList.remove('active'))
      // event.target.classList.add('active')

      setTimeout(() => {
        this.s8Active = page
      }, 10)
    },
    vmenuClick(page) {
      if (this.s8Active === page) {
        this.$router.push({ name: 'curriculum', params: { component: page } })
      }
    },
    vmenuExtend(page) {
      this.s8Active = page
    },
    getPopup() {
      this.$axios.get(`${this.$store.state.url}api/v1/popup`, {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }).then(res => {
        this.$store.state.popup = res.data.items
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#popup_we8vf9es {
  position: absolute;
  top: 150px;
  right: 80px;

  img {
    width: 330px;
    height: auto;
  }

  .close-popup {
    position: absolute;
    top: 0;
    right: 10px;
    width: 105px;
    height: 70px;
    cursor: pointer;
  }

  .close-oneday {
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 88px;
    cursor: pointer;
  }
}
#popup_8hqf239r {
  position: absolute;
  top: 100px;
  left: 340px;

  img {
    width: 500px;
    height: auto;
  }

  .row {
    background: #fff;
    font-size: 12px;

    .col {
      cursor: pointer;
    }
  }
}
#popup_ab48ee5b {
  position: absolute;
  top: 100px;
  left: 760px;

  img {
    width: 500px;
    height: auto;
  }

  .row {
    background: #fff;
    font-size: 12px;

    .col {
      cursor: pointer;
    }
  }
}
.home {
  background: url(../assets/images/71e67dded7e76.png) center center / cover no-repeat fixed;

  &::before {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(236, 236, 236, 0.85);
  }
}

.v-menu {
  display: flex;
  flex-wrap: wrap;
  height: 400px;

  .v-item {
    position: relative;
    padding: 25px;
    width: 150px;
    overflow: hidden;
    transition: .2s width;

    &.active {
      width: calc(100% - 300px);
      cursor: pointer;

      .v-item-text,
      .v-item-hidden {
        width: auto;
        height: auto;
        opacity: 1;
      }

      .v-item-head {
        margin: 0 0 20px;
        writing-mode: initial;
        text-orientation: initial;
      }

      .v-item-button {
        left: 25px;
        margin-left: 0;
      }
    }

    .v-item-hidden {
      display: inline-block;
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      vertical-align: top;
      transition: .2s opacity;
    }
  }

  .v-item-bg {
    &::after {
      content: " ";
      position: absolute;
      z-index: -1;
      margin-top: -25px;
      margin-left: -25px;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
    }
    img {
      position: absolute;
      z-index: -1;
      margin-top: -25px;
      margin-left: -25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .v-item-head {
    margin: 0 auto;
    line-height: 1;
    color: #fff;
    font-size: 38px;
    font-weight: 700;
    white-space: nowrap;
    writing-mode: vertical-rl;
    text-orientation: sideways;
  }

  .v-item-text {
    display: inline-block;
    width: 0;
    height: 0;
    color: #fff;
    font-size: 15px;
    white-space: nowrap;
    opacity: 0;
    overflow: hidden;
    transition: .2s opacity;
    // word-break: keep-all;
  }

  .v-item-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -35px;
    border-radius: 40px;
    width: 70px;
    height: 70px;
    background: #fff;
  }
}
.s1 {
  margin-bottom: 235px;
  h1 {
    // margin-top: -20px;
    margin-bottom: 0;
    width: 100%;
    // max-width: 800px;
    line-height: 1.1;
    font-family: 'Mulish';
    font-size: 72px;
    font-weight: 700;
  }

  h2 {
    margin-bottom: 50px;
    width: 100%;
    line-height: 1.1;
    color: #afafaf;
    font-family: 'Mulish';
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .c1,
  .c2 {
    margin-bottom: 20px;
    width: 100%;
    max-width: 750px;
    font-size: 14px;
    word-break: keep-all;

    h4 {
      width: 120px;
      float: left;
      margin-right: 30px;
      width: 120px;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .c2 {
    .btn {
      margin-right: 10px;
      border-radius:0;
      padding: 7px 30px;
    }

    .btn.btn-p {
      margin-right: 10px;
      border-radius:0;
      padding: 10px 30px;

      h6 {
        margin-bottom: 10px;
        font-size: 16px;
        color: #1d1d1d;
      }

      p {
        margin-bottom: 5px;
        font-size: 14px;
        color: #656565;
      }

      &:hover,
      &:focus {
        h6 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }

    .m {
      .btn {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-left: 7px;
        width: 100%;
        text-align: left;

        &>* {
          margin-bottom: 0;
        }

        h5 {
          font-weight: 700;
        }

        span {
          color: #656565;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .c3 {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.s2 {
  position: relative;
  margin-bottom: 200px;

  & > h1 {
    font-size: 60px;
    font-weight: 700;
  }

  #carouselHome {
    height: 500px;

    .carousel-item {
      img {
        display: block;
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 4rem;
      height: 4rem;
    }

    .carousel-control-next {
      justify-content: flex-end;
    }

    .carousel-control-prev {
      justify-content: flex-start;
    }
  }

  .v-text {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 5px;
    span {
      color: #fff;
    }
  }
}

.s3,
.s4,
.s5 {
  margin-top: 300px;
  .c1 {
    .v-text {
      float: left;
      margin: 18px 40px 0 10px;
      font-size: 14px;
      font-weight: 700;
    }

    .subheading {
      float: left;
      font-size: 60px;
      font-weight: 700;
    }

    .link {
      float: right;
      margin-right: 15px;
      color: #003671;
      font-size: 60px;
      text-decoration: none;
    }
  }

  .c2 {
    clear: both;
    padding: 15px;
    height: 700px;

    p {
      color: #fff;
    }
  }
}

.s3 {
  .c2 {
    background: url(../assets/images/f4149c5838059.jpg) center center / cover no-repeat;

    p {
      width: 440px;
    }
  }
}
.s4 {
  .c2 {
    background: url(../assets/images/3fd9dafa93500.jpg) center center / cover no-repeat;

    p {
      width: 660px;
    }
  }
}
.s5 {
  margin-bottom: 150px;
  .c2 {
    background: url(../assets/images/a700dfebc285e.jpg) center center / cover no-repeat;

    p {
      width: 510px;
    }
  }
}
.s6 {
  margin-bottom: 90px;
  .subheading {
    font-size: 36px;
    font-weight: 700;
  }
  .table {
    --bs-border-color: #d4d4d4;
    border-top: 1px solid var(--bs-border-color);
    tbody {
      a {
        color: #1d1d1d;
        font-size: 18px;
        text-decoration: none;
      }
    }
  }
}

.s7 {
  margin-bottom: 200px;
  & > h1 {
    font-size: 60px;
    font-weight: 700;
  }
  & > div {
    position: relative;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.s8 {
  margin-bottom: 200px;
  & > h1 {
    font-size: 60px;
    font-weight: 700;
  }
}

@media screen and (max-width: 991px) {
  #popup_we8vf9es {
    top: 120px;
    right: 30px;

    img {
      width: 240px;
      height: auto;
    }

    .close-oneday {
      left: 22%;
    }
  }
  #popup_8hqf239r {
    top: 100px;
    left: 30px;

    img {
      width: 250px;
    }
  }
  #popup_ab48ee5b {
    top: 100px;
    left: 30px;

    img {
      width: 250px;
    }
  }
  .home {
    background: #ececec;;
  }
  .v-menu {
    height: auto;
    .v-item {
      width: 100%;
      height: 100px;
      transition: .2s height;

      &.active {
        width: 100%;
        height: 250px;

        .v-item-button {
          top: 25px;
          right: 25px;
          left: initial;
        }
      }
    }

    .v-item-head {
      font-size: 30px;
      writing-mode: initial;
      text-orientation: initial;
    }

    .v-item-text {
      white-space: inherit;
      word-break: keep-all;
    }

    .v-item-button {
      top: 25px;
      right: 25px;
      left: initial;
      width: 50px;
      height: 50px;

      svg {
        width: 36px;
        height: 36px;

        transform: rotateZ(90deg);
      }
    }
  }
  .s1 {
    margin-top: 50px;
    margin-bottom: 30px;

    h1 {
      font-size: 48px;
      word-break: keep-all;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 35px;
      word-break: keep-all;
    }

    .c1 {
      margin-bottom: 30px;
      font-size: 14px;
      h4 {
        width: 100%;
        font-size: 22px;
      }
    }

    .c2 {
      margin-bottom: 60px;
      h4 {
        width: 100%;
        font-size: 22px;
      }

      .btn {
        width: 33.3333333%;
        margin: 0;
        padding: 7px 0;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:nth-child(2) {
          border-radius: 0;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .btn.btn-p {
        margin-right: 0;
      }
    }

    .c3 {
      display: none;
    }
  }
  .s2 {
    margin-bottom: 60px;
    padding-top: 0;
    // margin-right: calc(-.5 * var(--bs-gutter-x));
    // margin-left: calc(-.5 * var(--bs-gutter-x));
    overflow: hidden;

    & > h1 {
      margin-bottom: 30px;
      font-size: 36px;
    }

    #carouselHome {
      height: 350px;

      .carousel-item {
        img {
          height: 350px;
        }
      }

      .text {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .s3,
  .s4,
  .s5 {
    margin-top: 60px;
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    overflow: hidden;

    .c1 {
      .v-text {
        float: left;
        margin: 18px 40px 0 10px;
        font-size: 14px;
        font-weight: 700;
      }

      .text {
        margin-left: 15px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
      }

      .subheading {
        float: left;
        margin-left: 15px;
        line-height: 1;
        font-size: 36px;
        font-weight: 700;
      }

      .link {
        float: right;
        color: #003671;
        font-size: 0;
        text-decoration: none;
      }
    }

    .c2 {
      display: flex;
      clear: both;
      padding: 15px;
      height: 350px;

      p {
        color: #fff;
        word-break: keep-all;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .s5 {
    margin-bottom: 60px;
  }
  .s6 {
    margin-bottom: 50px;

    .subheading {
      margin-bottom: 30px;
      font-size: 26px;
    }

    .table {
      tbody {
        td {
          font-size: 12px;
          vertical-align: middle;
        }
        a {
          font-size: 16px;
        }
      }
    }
  }
  .s7 {
    margin-bottom: 60px;
  }
  .s8 {
    margin-bottom: 90px;
    & > h1 {
      margin-bottom: 30px;
      font-size: 36px;
    }
  }
}
</style>
