import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'

// const axiosInstance = axios.create({
//   withCredentials: true
// })

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(store).use(router).use(CKEditor).mount('#app')
