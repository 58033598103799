<template>
  <div :id="name" class="popup" :class="{'d-block': visible_m, 'd-none': !visible_m, 'd-lg-block': visible, 'd-lg-none': !visible}" v-if="isShown">
    <img v-if="!link" :src="url" alt="...">
    <a v-if="link" :href="link" class="link">
      <img :src="url" alt="...">
    </a>
    <div class="row g-0 border-top">
      <div class="col text-center p-3 border-end close-oneDay" @click="closePopup(name, true)">1일동안 보지 않음</div>
      <div class="col text-center p-3 close-popup" @click="closePopup(name)">닫기</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupComponent',
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    },
    top: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: null
    },
    pixel: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: 300
    },
    visible_m: {
      type: Boolean,
      default: true
    },
    top_m: {
      type: Number,
      default: 0
    },
    position_m: {
      type: String,
      default: null
    },
    pixel_m: {
      type: Number,
      default: null
    },
    width_m: {
      type: Number,
      default: 250
    }
  },
  data() {
    return {
      isShown: (this.visible || this.visible_m),
      left: null,
      right: null,
      left_m: null,
      right_m: null
    }
  },
  setup() {},
  created() {
    document.cookie.split(';').forEach((el) => {
      const [key, value] = el.split('=')
      const timestamp = Date.now()
      if (key.trim() === this.name && value > timestamp - 24 * 60 * 60 * 1000) {
        this.isShown = false
      }
    })

    if (this.position && this.pixel > 0) {
      if (this.position === 'left') {
        this.left = this.pixel
        this.right = null
      } else if (this.position === 'right') {
        this.left = null
        this.right = this.pixel
      }
    }

    if (this.position_m && this.pixel_m > 0) {
      if (this.position_m === 'left') {
        this.left_m = this.pixel_m
        this.right_m = null
      } else if (this.position_m === 'right') {
        this.left_m = null
        this.right_m = this.pixel_m
      }
    }
  },
  mounted() {},
  unmounted() {},
  methods: {
    closePopup(target, oneday = false) {
      this.isShown = false

      if (oneday === true) {
        document.cookie = target + '=' + Date.now()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  top: calc(v-bind(top) * 1px);
  left: calc(v-bind(left) * 1px);
  right: calc(v-bind(right) * 1px);
  z-index: 100;

  img {
    width: calc(v-bind(width) * 1px);
    height: auto;
  }

  .row {
    background: #fff;
    font-size: 12px;

    .col {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 991px) {
  .popup {
    top: calc(v-bind(top_m) * 1px);
    left: calc(v-bind(left_m) * 1px);
    right: calc(v-bind(right_m) * 1px);

    img {
      width: calc(v-bind(width_m) * 1px);
    }
  }
}
</style>
