<template>
  <div>
    <Transition>
      <div class="modal d-block" v-if="isShown">
        <div @click="closePopup('close')" class="modal-backdrop"></div>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <p v-html="message"></p>
            </div>
            <div class="modal-footer">
              <button @click="closePopup('close')" type="button" class="btn btn-outline-dark rounded-0">{{closeText}}</button>
              <button v-if="confirmView" @click="closePopup('confirm')" type="button" class="btn btn-dark rounded-0">{{confirmText}}</button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'AlertComponent',
  components: {},
  props: ['alertData', 'isShown', 'message', 'confirmText', 'closeText', 'confirmView', 'callback', 'callbackParams'],
  data() {
    return {
      // popupData: {
      //   isShown: this.isShown,
      //   message: this.message,
      //   confirmText: this.confirmText ? this.confirmText : '제출',
      //   closeText: this.closeText ? this.closeText : '확인',
      //   confirmView: this.confirmView
      // }
    }
  },
  // watch: {
  //   // 'alertData.isShown': {
  //   //   handler(val) {
  //   //     console.log('isShown - ' + val)
  //   //     console.log(this.alertData)
  //   //     this.popupData = this.alertData
  //   //   },
  //   //   deep: true
  //   // }
  //   isShown() {
  //     this.popupData.isShown = this.isShown
  //   },
  //   message() {
  //     this.popupData.message = this.message
  //   },
  //   confirmText() {
  //     this.popupData.confirmText = this.confirmText
  //   },
  //   closeText() {
  //     this.popupData.closeText = this.closeText
  //   },
  //   confirmView() {
  //     this.popupData.confirmView = this.confirmView
  //   }
  // },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    closePopup(action) {
      // let callback = {}
      // let params = {}
      // if (this.callback) {
      //   callback = { callback: this.callback }
      // }
      // if (this.callback && this.callbackParams) {
      //   params = { params: this.callbackParams }
      // }

      // this.clearPopupData()

      this.$emit('close', { action: action })

      if (
        ((this.confirmView === false && action === 'close') || (this.confirmView === true && action === 'confirm')) &&
        this.callback && typeof this.callback === 'function'
      ) {
        if (this.callbackParams) {
          this.callback(this.callbackParams)
        } else {
          this.callback()
        }
      }

      // this.clearPopupData()
      // console.log('clearPopupData')

      // this.$store.commit('clearPopupData')
    },
    clearPopupData() {
      this.popupData = {
        isShown: false,
        message: '',
        confirmText: '제출',
        closeText: '확인',
        confirmView: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}
.modal-content {
  padding: 30px 0;
}
.modal-backdrop {
  z-index: -1;
  opacity: .2;
}
.modal-body {
  p{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
}
.modal-footer {
  justify-content: space-evenly;
  border-top: 0;

  button {
    width: 40%;
  }
}
.btn-dark {
  background: #003671;
}
.btn-dark:hover {
  color: #003671;
  background: #fff;
}
</style>
